<template>
  <ion-page>
    <ion-content>
    <div>
        
    </div>
    </ion-content>
  </ion-page>  
</template>
<script>
import useLogout from '../composables/useLogout';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import {
  IonPage,
  IonContent,
} from '@ionic/vue';
export default {
    name: 'Logout',
    components: {
        IonPage,
        IonContent,
    },
    setup() {
    
      const { error, logout } = useLogout();
      const router = useRouter();
      const handleLogout = async () => {
      await logout();
      if (!error.value) {
        // console.log('User logged out');
        // Redirect on logout
        router.push({ name: 'Login' });
      }
    };
    onMounted(() => {
       handleLogout() 
    });
    return {

    }
    }
}
</script>