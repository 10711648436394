<template>
  <ion-page>
    <ion-header :translucent="false">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Home</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Home</ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <!-- <form @submit.prevent="getDistanceMatrix"> -->
        <form>
          <ion-grid v-if="!showForm">
            <!--  vehicles -->
              <ion-row>
                <ion-col size="12" size-lg="6" size-md="6">
                  <ion-text color="primary">
                    <h3>Select Vehicle</h3>
                  </ion-text>
                    <ion-row v-for="vehicle in dataResults" :key="vehicle.id"
                    @click="showRequestForm(vehicle.categoryName, vehicle.imagePath)"
                    class="ion-align-items-start">
                      <ion-col size="6" size-lg="6" size-md="6">
                            <img :src="`assets/${vehicle.imagePath}`">
                      </ion-col>
                      <ion-col size="6" size-lg="6" size-md="6">
                            <ion-label>
                              <h2>{{ vehicle.categoryName }}</h2>
                              <h3>Maximum load {{ vehicle.maxLoad }} Tonnes</h3>
                            </ion-label>
                      </ion-col>
                      </ion-row>
                </ion-col>
              </ion-row>
              <!-- end -->
            </ion-grid>
            <!-- end -->
            <ion-grid v-if="showForm">
                <ion-row>
                  <!-- select vehicle -->
                  <ion-col size="12" size-lg="6" size-md="6">
                    <ion-card v-if="selectedVehicle">
                      <ion-item>
                        <ion-icon :icon="car" slot="start"></ion-icon>
                        <ion-label>{{selectedVehicle}}</ion-label>
                      </ion-item>

                      <ion-card-content>
                        <img :src="`assets/${selectedVehicleImage}`">
                      </ion-card-content>
                    </ion-card>
                </ion-col>
                <!-- end -->
              </ion-row>

            <ion-row>
              <ion-col size="12" size-lg="6" size-md="6">
                  <ion-item>
                    <ion-label>Pickup date <span>*</span></ion-label>
                    <ion-datetime display-format="YYYY-MMMM-DD HH:mm" :min="today" v-model="pickupDate"></ion-datetime>
                  </ion-item>
              </ion-col>
            </ion-row>


            <!-- <ion-row v-if="pickupDate">
              <ion-col size="12" size-lg="6" size-md="6">
                  <ion-item>
                    <ion-text color="primary">
                      Type in the first 3 letters of location.
                    </ion-text>
                  </ion-item>
              </ion-col>
            </ion-row> -->

            <ion-row v-if="pickupDate">
              <ion-col size="12" size-lg="6" size-md="6">
                  <ion-item>
                    <ion-label position="stacked">Pick Up [Type in the first 3 letters of location.]</ion-label>
                    <ion-input type="text" placeholder="Pickup" v-model="pickupName" @keyup="getPlaceDetail('pickup')"></ion-input>
                  </ion-item>
              </ion-col>
            </ion-row>
            <ion-row v-if="pickupLocation">
              <ion-col size="12" size-lg="6" size-md="6">
                  <ion-list>
                    <ion-item v-for="location in pickupLocation" :key="location.place_id">
                      <ion-label @click="getPlaceId(location.place_id, location.description, 'pickup')">
                        {{ location.description }}
                      </ion-label>
                    </ion-item>
                  </ion-list>
              </ion-col>
            </ion-row>
            <!-- card for pickup -->
              <ion-row>
                <ion-col size="12" size-lg="6" size-md="6">
                    <ion-card v-if="pickupPoint">
                      <ion-item>
                        <ion-icon :icon="pin" slot="start"></ion-icon>
                        <ion-label>{{pickupPoint}}</ion-label>
                      </ion-item>

                      <ion-card-content>
                        <ion-text color="primary">
                          Add pick-up contact details *
                          <ion-toggle @ionChange="selfContact"></ion-toggle> Self
                        </ion-text>
                        <ion-row>
                          <ion-col size="6">
                            <ion-input type="text" placeholder="Contact Name" v-model="pickUpContact" required autocomplete="off"></ion-input>
                          </ion-col>
                          <ion-col size="6">
                            <ion-input type="tel" placeholder="Phone number" v-model="pickUpContactPhone" required autocomplete="off"></ion-input>
                          </ion-col>
                        </ion-row>
                      </ion-card-content>
                    </ion-card>
              </ion-col>
            </ion-row>
            <!-- end -->

            <ion-row v-if="pickupPoint">
              <ion-col size="12" size-lg="6" size-md="6">
                  <ion-item>
                    <ion-label position="stacked">Destination [Type in the first 3 letters of location.]</ion-label>
                    <ion-input type="text" placeholder="Destination" v-model="dropOffName" @keyup="getPlaceDetail('dropoff')"></ion-input>
                  </ion-item>
              </ion-col>
            </ion-row>
            <ion-row v-if="dropOffLocation">
              <ion-col size="12">
                  <ion-list>
                    <ion-item v-for="location in dropOffLocation" :key="location.place_id">
                      <ion-label @click="getPlaceId(location.place_id, location.description, 'dropoff')">
                        {{ location.description }}
                      </ion-label>
                    </ion-item>
                  </ion-list>
              </ion-col>
            </ion-row>
            <!-- card for destination -->
              <ion-row>
                <ion-col size="12" size-lg="6" size-md="6">
                <ion-card v-if="dropoffPoint">
                  <ion-item>
                    <ion-icon :icon="pin" slot="start"></ion-icon>
                    <ion-label>{{dropoffPoint}}</ion-label>
                  </ion-item>
                        <ion-card-content>
                            <ion-text color="primary">
                            Add destination contact details *
                          </ion-text>
                          <ion-row>
                            <ion-col size="6">
                              <ion-input type="text" placeholder="Contact Name" v-model="dropoffContact" required autocomplete="off"></ion-input>
                            </ion-col>
                            <ion-col size="6">
                              <ion-input type="tel" placeholder="Phone number" v-model="dropoffContactPhone" required autocomplete="off"></ion-input>
                            </ion-col>
                          </ion-row>
                        </ion-card-content>
                      </ion-card>
                </ion-col>
              </ion-row>
              <!-- end  -->
              <!-- show only if pickup and drop locations have been selected -->
            <div v-if="dropoffPoint && pickupPoint">
            <ion-row v-if="!computedCost && hideBookBtn">
              <ion-col size="6">
                <ion-button color="primary" @click="cancelOrder">Cancel</ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button color="primary"
                @click="getDistanceMatrix">
                Book
                </ion-button>
              </ion-col>
            </ion-row>
            <!--  -->
            <ion-row v-if="!computedCost && !hideBookBtn">
              <ion-col size="6">
                Getting Price...
              </ion-col>
            </ion-row>
            </div>
            <div v-if="!dropoffPoint && !pickupPoint">
            <ion-row>
              <ion-col size="6" size-lg="6" size-md="6">
                <ion-button color="primary" @click="cancelOrder">Cancel</ion-button>
              </ion-col>
            </ion-row>
            </div>
            <!-- spinner -->
            <!--  -->
            <!-- Price card -->
              <ion-row>
                <ion-col size="12" size-lg="6" size-md="6">
                    <ion-card v-if="computedCost">
                      <ion-item>
                        <ion-icon :icon="cart" slot="start"></ion-icon>
                        <ion-label>Cost: GH&cent; {{computedCost}}</ion-label>
                      </ion-item>

                      <ion-card-content>
                        <ion-row>
                          <ion-col size="6">
                             <ion-button color="primary" @click="cancelOrder">Cancel</ion-button>
                          </ion-col>
                          <ion-col size="6">
                             <ion-button color="primary" @click="submitData">Accept</ion-button>
                          </ion-col>
                        </ion-row>
                      </ion-card-content>
                    </ion-card>
              </ion-col>
            </ion-row>
            <!-- end -->
          </ion-grid>
        </form>

      </div>
          <!-- fab for home -->
    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button @click="goToDeliveries">
        <ion-icon :icon="cube"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonInput,
  IonItem,
  IonList,
  IonText,
  IonCard, IonCardContent,
  IonButton,
  IonIcon,
  IonDatetime,
  IonFab, 
  IonFabButton,
  IonToggle,
  // IonImg,
  // IonAvatar,
  } from '@ionic/vue';
import { 
  cube
} from 'ionicons/icons';
import firebase from 'firebase/app';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { pin, car, cart } from 'ionicons/icons';
import { timestamp, pentermFirestore } from '../firebase/config';
import getUser from '../composables/getUser';
export default {
  name: 'Home',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonItem,
    IonList,
    IonText,
    IonCard, IonCardContent,
    IonButton,
    IonIcon,
    IonDatetime,
    IonFab, 
    IonFabButton,
    IonToggle,
    // IonImg,
    // IonAvatar,
  },
  setup() {
    const { user } = getUser();
    const router = useRouter();
    const pickupName = ref('')
    const dropOffName = ref('')
    const pickupLocation = ref('')
    const dropOffLocation = ref('')
    const dropOffPlaceId = ref('');
    const pickupPlaceId = ref('');
    const pickupPoint = ref('');
    const dropoffPoint = ref('');
    // Trip details
    const pickUpContact = ref('');
    const pickUpContactPhone = ref('');
    // const showPickup = ref(true);
    const dataResults = ref('');
    const dump = ref('');
    const error = ref('');
    const dropoffContact = ref('');
    const dropoffContactPhone = ref('');
    const pickupDate = ref('');
    const computedCost = ref(null);
    const pickupGeoPoint = ref('');
    const dropoffGeoPoint = ref('');
    // Get current date to restrict datepicker
    const ttday = new Date()
    let currentDate;
    let currentMonth;
    const showForm = ref(false);
    const selectedVehicle = ref('')
    const selectedVehicleImage = ref('');
    // const computedCost = ref('');
    const jobData = ref('')
    const hideBookBtn = ref(true);
    const contactsMissing = ref();

    const selfContact = () => {
      if (pickUpContact.value && pickUpContactPhone.value){
        pickUpContact.value = ''
        pickUpContactPhone.value = ''
      } else {
        pickUpContact.value = user.value.displayName;
        pickUpContactPhone.value = user.value.phoneNumber;
      }
    }
    const getContacts = () => {
      if (pickUpContact.value.length> 0 && pickUpContactPhone.value.length > 0){
        contactsMissing.value = '';
      } else {
        contactsMissing.value = 'missing contacts';
      }
    }


    // format month value add 0
    if (ttday.getMonth() < 10){
      currentMonth = `0${ttday.getMonth() + 1}`;
    } else {
      currentMonth = ttday.getMonth();
    }
    // format date value add 0
    if (ttday.getDate() < 10){
      currentDate = `0${ttday.getDate()}`;
    } else {
      currentDate = ttday.getDate();
    }
    // 
    const today = `${ttday.getFullYear()}-${currentMonth}-${currentDate}`;
    // end

    const showRequestForm = (vehicle, image) => {
      showForm.value = !showForm.value
      selectedVehicle.value = vehicle,
      selectedVehicleImage.value = image
    }

    // Get Vehicle Categories
    const priceSchedules = async () => {
      try {
        const res = await pentermFirestore.collection('vehicleCategories').orderBy('createdDate', 'desc')
          .onSnapshot((snap) => {
            // console.log(snap.docs);
            dataResults.value = snap.docs.map((priceSchedule) => (
              { ...priceSchedule.data(), id: priceSchedule.id }
            ));
          });
        // console.log('waiting for data');
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    priceSchedules();

        // Get Place
    const getPlaceDetail = (source) => {
      const getPlaceDetails = firebase.functions().httpsCallable('getAddress');
      if (source === 'pickup') {
        const location = pickupName.value;
        getPlaceDetails({ place: location })
          .then((result) => {
            pickupLocation.value = result.data.places.predictions;
          });
      }
      if (source === 'dropoff') {
        const location = dropOffName.value;
        getPlaceDetails({ place: location })
          .then((result) => {
            dropOffLocation.value = result.data.places.predictions;
          });
      }
    };
    // Get Coordinates
    /**
     * Get Place Id for distance matrix calculations 
     * Geo Geo Points from place ID
     * Reset some fields
     */
    const getPlaceId = (place, name, source) => {
      if (source === 'dropoff') {
        dropOffPlaceId.value = place;
        dropoffPoint.value = name;
        dropOffName.value = ''
        dropOffLocation.value = ''
        // Get Long and Lat using Place Id
        const getGeoPoints = firebase.functions().httpsCallable('getCoordinatates');
        getGeoPoints({ placeId: place}).then((result) => {
          // console.log(result.data.coords.result.geometry.location);
          dropoffGeoPoint.value =  new firebase.firestore.GeoPoint(
                result.data.coords.result.geometry.location.lat, result.data.coords.result.geometry.location.lng,
              );
        });
      }
      if (source === 'pickup') {
        pickupPlaceId.value = place;
        pickupPoint.value = name;
        pickupName.value = ''
        pickupLocation.value = ''
        // Get Long and Lat using Place Id
        const getGeoPoints = firebase.functions().httpsCallable('getCoordinatates');
        getGeoPoints({ placeId: place}).then((result) => {
          // console.log(result.data.coords.result.geometry.location);
          pickupGeoPoint.value =  new firebase.firestore.GeoPoint(
                result.data.coords.result.geometry.location.lat, result.data.coords.result.geometry.location.lng,
              );
        });
      }
    };
    // Get Distance MAtrix
    const getDistanceMatrix = () => {
      hideBookBtn.value = false;
      const getDistnace = firebase.functions().httpsCallable('getDistanceMatrix');
      getDistnace({ pickup: pickupPlaceId.value, dropoff: dropOffPlaceId.value })
        .then((result) => {
        // Get price of trip from vehicle type and total distance
          const tripDistance = result.data.distance.rows[0].elements[0].distance.text
          const totalDistance = Number(tripDistance.replace(' km', ''));
          const getTripPrice = firebase.functions().httpsCallable('getTripPrice');

          let pickupLocal = result.data.distance.origin_addresses[0];
          let destLocal = result.data.distance.destination_addresses[0];
          let distanceToTravel = result.data.distance.rows[0].elements[0].distance.text;

          // console.log(result);

          // Get trip cost after results is returned
          if (result.data.distance.status === 'OK')
          {
            // Call get price cloud function
            getTripPrice({ vehicle: selectedVehicle.value, distance: totalDistance })
            .then((result) => {
              // console.log('Functions Price', result.data);
              // console.log('Functions Price', result.data);

              computedCost.value = result.data.computedCost;

              // prepare data to submit

              jobData.value = {
                  tripId: `A${Math.floor(Math.random() * 10)}${Date.now()}`,
                  // customer: customer.value,
                  // phone: customerPhone.value,
                  // email: customerEmail.value,
                  pickup: pickupLocal,
                  pickupCoordinates: pickupGeoPoint.value,
                  pickupDate: new Date(pickupDate.value),
                  pickUpContact: pickUpContact.value,
                  pickUpContactPhone: pickUpContactPhone.value,
                  dropoff: destLocal,
                  dropoffCoordinates: dropoffGeoPoint.value,
                  dropoffContact: dropoffContact.value,
                  dropoffContactPhone: dropoffContactPhone.value,
                  distance: distanceToTravel,
                  cost: computedCost.value,
                  computedCost: computedCost.value,
                  itemDesc: '',
                  itemWeightDesc: '',
                  itemWeight: '',
                  noOfBags: 0,
                  weightOfBag: 0,
                  finalRevenue: 0,
                  truckId: '',
                  fuelCost: 0,
                  finalFuel: 0,
                  vehicleType: selectedVehicle.value,
                  vehicleTypeImage: '',
                  jobExpenses: 0,
                  jobMaintenace: 0,
                  jobComments: 'None',
                  agentName: user.value.displayName,
                  agentPhone: user.value.phoneNumber,
                  agentEmail: user.value.email,
                  uid: user.value.uid,
                  status: 0,
                  createdDate: timestamp(),
                  updatedDate: timestamp(),
                };
                // console.log(jobData.value);
            }).catch((err) => { console.log(err.message)});
          }
          // Submit trip
          // const submitData = async () => {
          //     await pentermFirestore.collection('loadBoard').add(addTrip);
          //   };
          //   submitData();
            // end
        }).catch((err) => { console.log(err)});
    };

    const cancelOrder = () => {
      pickupDate.value = '';
      dropoffPoint.value = '';
      pickupPoint.value = '';
      selectedVehicle.value = '';
      computedCost.value = '';
      jobData.value = '';
      pickUpContact.value = '';
      pickUpContactPhone.value = '';
      dropoffContact.value = '';
      dropoffContactPhone.value = '';
      // dropOffName.value = '';
      // dropOffLocation.value = '';
      showForm.value = false;
      hideBookBtn.value = true;

      
    }
    const submitData = async () => {
        await pentermFirestore.collection('loadBoard').add(jobData.value);
        cancelOrder();
        router.push({ name: 'Deliveries' });
      };

    const goToDeliveries = () => {
        router.push({ name: 'Deliveries' });
        
    };
      const spinner = {
        'src': '../../assets/Spin-1s-48px.gif'
      };


    onMounted(() => {
      getContacts()
    });
    
    return {
      cube,
      spinner,
      goToDeliveries,
      selfContact,
      contactsMissing,
      today,
      pickupName,
      dropOffName,
      getPlaceDetail,
      pickupLocation,
      dropOffLocation,
      dropOffPlaceId,
      pickupPlaceId,
      getPlaceId,
      pin,
      car,
      cart,
      pickupPoint,
      dropoffPoint,
      getDistanceMatrix,
      dataResults,
      showForm,
      showRequestForm,
      selectedVehicle,
      selectedVehicleImage,
      pickupDate,
      pickUpContact,
      pickUpContactPhone,
      dropoffContact,
      dropoffContactPhone,
      computedCost,
      hideBookBtn,
      submitData,
      cancelOrder,
    }
  }
}
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>