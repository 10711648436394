<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>My Deliveries</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">My Deliveries</ion-title>
        </ion-toolbar>
      </ion-header>

<div v-if="dataResults">
    <ion-card v-for="trip in dataResults" :key="trip.id">
        <ion-item>
        <!-- <ion-icon :icon="car" slot="start"></ion-icon> -->
        <ion-label>
             
            <ion-grid class="page-font">
              <ion-row>
                <ion-col size="4" size-lg="4" size-md="4">
                    <img :src="`assets/Flatbed.png`" width="100">
                </ion-col>
                <ion-col size="8" size-lg="8" size-md="8">
                    <div>
                        <ion-text v-if="trip.status === 0">
                         Booked, pending pickup.
                        </ion-text>
                        <ion-text v-if="trip.status === 1">
                         Trip accepted.
                        </ion-text>
                        <ion-text v-if="trip.status === 2">
                         Driver on the way.
                        </ion-text>
                        <ion-text v-if="trip.status === 3">
                         Your goods are being delivered.
                        </ion-text>
                        <ion-text v-if="trip.status === 4">
                         Goods delivered.
                        </ion-text>
                    </div>
                </ion-col>
              </ion-row>
            </ion-grid>
        </ion-label>
        </ion-item>

        <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="12" size-md="12" class="page-font">
                    <div>
                        <ion-text>
                            Booked: {{ trip.createdDate.toDate().toDateString() }}
                        </ion-text>
                    </div>
                    <div>
                        <ion-text>
                            From: {{ trip.pickup }}
                        </ion-text>
                    </div>
                    <div>
                        <ion-text>
                            To: {{ trip.dropoff }}
                        </ion-text>
                    </div>
                </ion-col>
              </ion-row>
            </ion-grid>
        </ion-card-content>
    </ion-card>
</div>
    <!-- fab for home -->
    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button @click="goHome">
        <ion-icon :icon="home"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <!-- fab for new trip -->
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="bookJob">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    
    </ion-content>
  </ion-page>
</template>

<script>
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonCard, IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    // IonFabList,
    IonFab, 
    IonFabButton,
    IonIcon,
    } from '@ionic/vue';
import { 
  add, home
} from 'ionicons/icons';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import getUser from '../composables/getUser';
import { pentermFirestore } from '../firebase/config';


export default {
  name: 'Folder',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonCard, IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    // IonFabList,
    IonFab, 
    IonFabButton,
    IonIcon,
    
  },
  setup() {
    const { user } = getUser();
    const router = useRouter();
    const dataResults = ref([]);
    const error = ref('');
    const dump = ref('');
    const fetchData = 25;

    const jobs = async () => {
      try {
        const res = await pentermFirestore.collection('loadBoard').where('uid', '==', user.value.uid)
          .orderBy('createdDate', 'desc').limit(fetchData)
          .onSnapshot((snap) => {
            dataResults.value = snap.docs.map((trip) => ({ ...trip.data(), id: trip.id }));
            // last = snap.docs[snap.docs.length - 1];
            // records.value = snap.docs.length;
            // disableBtn.value = false;
          });
        dump.value = res;
      } catch (err) {
        error.value = err.message;
      }
    };
    const bookJob = () => {
        router.push({ name: 'Home' });
        
    }
    const goHome = () => {
        router.push({ name: 'Home' });
        
    }
    onMounted(() => {
        jobs();
    });

      

    return {
        dataResults,
        add,
        home,
        bookJob,
        goHome,

      }
  }
}
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
.page-font {
    font-size: 0.8em;
}
</style>