<template>
  <ion-page>
    <ion-content>

      <div v-if="showLogin">
    
      <div id="container">
        <form @submit.prevent="handleSubmit">
          <div class="logowidth">
            <ion-img :src="logo.src"></ion-img>
            <p>
              <ion-label>Redefining Logistics</ion-label>
            </p> 
          </div>
        
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input type="email" v-model="email" required autocomplete="off"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" v-model="password" required autocomplete="off"></ion-input>
        </ion-item>

        <div id="error-container">
          <p>
            {{ error }}            
          </p>

          </div>
        <div id="btn-container">
          <ion-button expand="block" type="submit" color="penterm-btn-red">Login</ion-button>
        </div>

        </form>
      </div>
        <div id="container-signup"  @click="toggleSignUp">
          <ion-text color="medium">
            <h5>New User? Sign Up</h5>
          </ion-text> 
        </div>
      </div>

      <div v-if="!showLogin">
      <div id="container" v-if="!showLogin">
        <form @submit.prevent="handleSignUp">
          <div class="logowidth">
            <ion-img :src="logo.src"></ion-img>
            <p>
              <ion-label>Redefining Logistics</ion-label>
            </p> 
          </div>
        
        <ion-item>
          <ion-label position="floating">Full Name</ion-label>
          <ion-input type="text" v-model="displayName" required></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input type="email" v-model="email" required autocomplete="off"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Password</ion-label>
          <ion-input type="password" v-model="password" required autocomplete="off"></ion-input>
        </ion-item>

        <div id="error-container">
          <p>
            {{ signUpError }}            
          </p>
          </div>
          <p>
            <ion-text color="medium">
              By signing up you agree to our terms and conditions.
          </ion-text>
          </p>

        <div id="btn-container">
          <ion-button expand="block" type="submit" color="penterm-btn-red">Sign Up</ion-button>
        </div>

        </form>
      </div>
        <div id="container-signup"  @click="toggleSignUp">
          <ion-text color="medium">
            <h5>Login</h5>
          </ion-text> 
        </div>
      </div>

    </ion-content>
  </ion-page>
</template>

<script>
import getUser from '../composables/getUser';
import useLogin from '../composables/useLogin';
import useSignup from '../composables/useSignup';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import {
  IonPage,
  IonLabel,
  IonInput,
  IonItem,
  IonImg,
  IonText,
  IonContent,
  IonButton,
  } from '@ionic/vue';

export default  {
  name: 'Location',
  components: { 
    IonPage,
    IonLabel,
    IonInput,
    IonItem,
    IonImg,
    IonText,
    IonContent,
    IonButton,
    },
setup() {
  const { user } = getUser();
  const displayName = ref('');
  const email = ref('');
  const password = ref('');
  const router = useRouter();
  const loginError = ref('');
  const showLogin = ref(true);

  const {
      error, login,
    } = useLogin();
  
  const { signUpError, signup } = useSignup();
  
  const logo = {
    'src': '../../assets/penterm-login.png'
  };

  const toggleSignUp = () => {
    showLogin.value = !showLogin.value  
  }

  const handleSubmit = async () => {
      await login(email.value, password.value);
      if (!error.value) {
        email.value = '';
        password.value = '';
        router.go({ name: 'Home' });
      }
    };

  const handleSignUp = async () => {
      // console.log(displayName.value, email.value, password.value);
      await signup(email.value, password.value, displayName.value);
      if (!signUpError.value) {
        // console.log('User Signed Up');
        // context.emit('signup');
        displayName.value = '';
        email.value = '';
        password.value = '';
        router.go({ name: 'Home' });
      }
    };

  onMounted(() => {
    if (user) {
      router.push({ name: 'Home' });
    }
    
  })

  return{
    showLogin,
    toggleSignUp,
    displayName,
    email,
    error,
    password,
    handleSubmit,
    logo,
    loginError,
    signUpError,
    handleSignUp,
  }
  
}
}
</script>
<style scoped>
#container {
  text-align: center;
  position: absolute;
  max-width: 300px;
  margin: auto;
  left: 0;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}
#container-signup {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 80%;
  transform: translateY(-50%);
  cursor: pointer;
}
#btn-container {
  width: 30%;
  margin: auto;

}
#error-container {
  width: 90%;
  margin: auto;
  color: tomato;

}
.logowidth{
  width: 250px;
  margin: auto;
}
.bgcolor {
  background-color: #ae0c00;
}

</style>