import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth } from '../firebase/config';

const error = ref(null);
const adminStatus = ref(null);

const login = async (email, password) => {
  error.value = null;

  try {
    const res = await pentermAuth.signInWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete login');
    }
    // console.log(res);
    if (window.location.hostname === 'localhost') {
      firebase.functions().useEmulator('localhost', 5001);
    }

    const getIsAdmin = firebase.functions().httpsCallable('checkUserAdminState');
    getIsAdmin({ email: 'no-email' }).then((result) => {
      adminStatus.value = result.data.isAdmin;
    }).catch((err) => {
      // Getting the Error details.
      console.log('Error Code ', err.code);
      console.log('Error Message ', err.message);
      console.log('Error Message ', err.details);
      // ...
    });

    error.value = null;
    return res;
  } catch (err) {
    // console.log(err.value);
    error.value = 'Incorrect Login Credentials';
    return error;
  }
};

const passwordReset = async (email) => {
  const actionCodeSettings = {
    url: 'https://admin.pentermlogistics.com/login',
    // iOS: {
    //   bundleId: 'com.example.ios',
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12',
    // },
    handleCodeInApp: true,
    // When multiple custom dynamic link domains are defined, specify which
    // one to use.
    // dynamicLinkDomain: 'example.page.link',
  };
  pentermAuth.sendPasswordResetEmail(email, actionCodeSettings).then(() => {
    // Email sent.
    console.log('Email sent');
  }).catch(() => {
  // An error happened.
    error.value = 'Problem sending email';
  });
};

const useLogin = () => ({
  error, login, adminStatus, passwordReset,
});

export default useLogin;
