import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import Home from '../views/Home.vue';
import Deliveries from '../views/Deliveries.vue';
import { pentermAuth } from '../firebase/config';
// auth guard
const requireAuth = (to, from, next) => {
  const user = pentermAuth.currentUser;
  // console.log('current user..is...', user);
  if (!user) {
    next({ name: 'Login' });
  } else {
    next();
  }
};
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: '/deliveries',
    name: 'Deliveries',
    component: Deliveries,
    beforeEnter: requireAuth,
  },
  {
    path: '/folder/Inbox',
    name: 'Inbox',
    // component: Logout
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue'),
    beforeEnter: requireAuth,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
