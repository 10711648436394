import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth } from '../firebase/config';

const signUpError = ref(null);
const setAccountType = ref('');

const signup = async (email, password, displayName) => {
  signUpError.value = null;

  try {
    const res = await pentermAuth.createUserWithEmailAndPassword(email, password);
    if (!res) {
      throw new Error('Could not complete signup');
    }
    // Update username after sigup into dB
    await res.user.updateProfile({ displayName });
    // set user type
    const setAccessType = firebase.functions().httpsCallable('pentermAccountType');
    setAccessType({ uid: res.user.uid, accessType: 'agent' }).then((result) => {
      setAccountType.value = result.data.status;
    }).catch((err) => {
      // Getting the Error details.
      // console.log('Error Code ', err.code);
      // console.log('Error Message ', err.message);
      // console.log('Error Message ', err.details);
      setAccountType.value = err.message;
      // ...
    });
    // End of setting user type

    // Email Verification
    const userVerify = pentermAuth.currentUser;
    const actionCodeSettings = {
      url: 'https://admin.pentermlogistics.com/dashboard',
      // iOS: {
      //   bundleId: 'com.example.ios',
      // },
      // android: {
      //   packageName: 'com.example.android',
      //   installApp: true,
      //   minimumVersion: '12',
      // },
      handleCodeInApp: true,
      // When multiple custom dynamic link domains are defined, specify which
      // one to use.
      // dynamicLinkDomain: 'example.page.link',
    };
    userVerify.sendEmailVerification(actionCodeSettings).then(() => {
      // Email sent.
      console.log('Email Sent');
    }).catch((err) => {
      // An error happened.
      console.log(err);
    });
    // End
    // console.log(res.user);

    signUpError.value = null;

    return res;
  } catch (err) {
    // console.log(err.message);
    signUpError.value = err.message;
    return signUpError;
  }
};

const useSignup = () => ({ signUpError, signup, setAccountType });

export default useSignup;
