import { ref } from 'vue';
import firebase from 'firebase';
import { pentermAuth } from '../firebase/config';

const user = ref(pentermAuth.currentUser);
const adminStatus = ref(null);
const userDetails = ref(null);
// const user = ref(pentermAuth.currentUser)

pentermAuth.onAuthStateChanged((_user) => {
//   console.log('User state change. current user is ', _user);
  if (window.location.hostname === 'localhost') {
    firebase.functions().useEmulator('localhost', 5001);
  }
  user.value = _user;
  // User Custom Claims
  if( user.value ) {
    pentermAuth.currentUser.getIdTokenResult()
      .then((idTokenResult) => {
        userDetails.value = idTokenResult.claims;
      });
  }

});

const getUser = () => ({ user, adminStatus, userDetails });

export default getUser;
