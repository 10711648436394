<template>
  <IonApp>
    <IonSplitPane content-id="main-content" v-if="user" class="custom-ion-menu">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>
              <ion-img :src="logo.src"></ion-img>
            </ion-list-header>
          <ion-list id="labels-list">

            <ion-item>
              <ion-icon slot="start" :ios="personOutline" :md="personOutline"></ion-icon>
              <ion-label>{{ user.displayName }}</ion-label>
            </ion-item>
          </ion-list>
  
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="forward" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
    <ion-router-outlet id="main-content" v-if="!user"></ion-router-outlet>
  </IonApp>
</template>

<script>
import { 
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonImg, } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { 
  archiveOutline, archiveSharp,
  bookmarkOutline, bookmarkSharp, heartOutline,
  heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp,
  trashOutline, trashSharp, warningOutline, warningSharp,
  personOutline,
  logInOutline,
  logInSharp,
  cubeOutline,
  cubeSharp,
  cogOutline,
  cogSharp,
  shieldOutline,
  shieldSharp,
  homeOutline,
  homeSharp
  } from 'ionicons/icons';
// defined
import getUser from './composables/getUser';
export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonRouterOutlet, 
    IonSplitPane,
    IonImg,
  },
  setup() {
    const { user } = getUser();
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Home',
        url: '/',
        iosIcon: homeOutline,
        mdIcon: homeSharp
      },
      {
        title: 'Deliveries',
        url: '/deliveries',
        iosIcon: cubeOutline,
        mdIcon: cubeSharp
      },
      // {
      //   title: 'Settings',
      //   url: '/folder/Settings',
      //   iosIcon: cogOutline,
      //   mdIcon: cogSharp
      // },
      // {
      //   title: 'Help',
      //   url: '/folder/Help',
      //   iosIcon: shieldOutline,
      //   mdIcon: shieldSharp
      // },
      {
        title: 'Logout',
        url: '/logout',
        iosIcon: logInOutline,
        mdIcon: logInSharp
      }
    ];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    const logo = {
    'src': '../../assets/penterm_logo_long.png'
    };
    const route = useRoute();
    
    return {
      logo, 
      user,
      selectedIndex,
      appPages, 
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      personOutline,
      logInOutline,
      logInSharp,
      cubeOutline,
      cubeSharp,
      cogOutline,
      cogSharp,
      shieldOutline,
      shieldSharp,
      homeOutline,
      homeSharp,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>
.custom-ion-menu {
  --side-width: 200px;
}
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>