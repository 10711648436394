import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// import firebase from 'firebase'
// import * as pentermAdmin from 'firebase-admin';

let databaseURLx = '';
if (window.location.hostname === 'localhost') {
  databaseURLx = 'http://localhost:9000/?ns=penterm-3e586';
} else {
  databaseURLx = 'https://penterm-3e586-default-rtdb.europe-west1.firebasedatabase.app';
}

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCgK75bn-IzWYaUC6lSEzOSRAhMXKVExPQ',
  authDomain: 'penterm-3e586.firebaseapp.com',
  projectId: 'penterm-3e586',
  databaseURL: databaseURLx,
  storageBucket: 'penterm-3e586.appspot.com',
  messagingSenderId: '1062856155599',
  appId: '1:1062856155599:web:c98f5dffca43fe22c032ba',
  measurementId: 'G-W0X074GR6L',
};

// init

firebase.initializeApp(firebaseConfig);
// firebase.functions().useEmulator('localhost', 5001);

// Auth
const pentermAuth = firebase.auth();
if (window.location.hostname === 'localhost') {
  pentermAuth.useEmulator('http://localhost:9099', { disableWarnings: true });
}

// Firestore config
const pentermFirestore = firebase.firestore();
if (window.location.hostname === 'localhost') {
  pentermFirestore.useEmulator('localhost', 8080);
}

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  pentermFirestore, timestamp, pentermAuth, firebaseConfig,
};
